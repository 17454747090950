<template>
  <md-card>
    <md-card-header :data-background-color="getTheme">
      <h4 class="title">
        {{
          editMode
            ? $t("device.deviceForm.titleFormEdit")
            : $t("device.deviceForm.titleFormCreate")
        }}
      </h4>
    </md-card-header>

    <md-card-content>
      <div class="container-fluid">
        <v-form ref="form">
          <div class="row">
            <div class="col-lg-4 col-12">
              <div class="row d-flex align-center m-0 mb-5">
                <div class="col col-10">
                  <TGCTextField
                    :label="$t('device.deviceForm.identifier')"
                    v-model="form.identifier"
                    :rules="[
                      rules.required,
                      (value) => rules.minLength(value, 0),
                      (value) => rules.maxLength(value, 100),
                    ]"
                  />
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="20"
                      class="col col-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{ $t("tooltips.deviceForm.identifier") }}</span>
                </v-tooltip>
              </div>
            </div>
            <!-- <div class="col-lg-4 col-12">
              <div class="row d-flex align-center m-0 mb-5">
                <div class="col col-10">
                  <TGCTextField
                    label="MAC"
                    v-model="form.mac"
                    :rules="[
                      rules.required,
                      (value) => rules.minLength(value, 0),
                      (value) => rules.maxLength(value, 100),
                    ]"
                    counter="50"
                  />
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="20"
                      class="col col-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{ $t("tooltips.mac") }}</span>
                </v-tooltip>
              </div>
            </div> -->
            <div class="col-lg-4 col-12">
              <div class="row d-flex align-center m-0 mb-5">
                <div class="col col-10">
                  <TGCTextField
                    :label="$t('device.deviceForm.alias')"
                    v-model="form.alias"
                    :rules="[
                      rules.required,
                      (value) => rules.minLength(value, 0),
                      (value) => rules.maxLength(value, 100),
                    ]"
                  />
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="20"
                      class="col col-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{ $t("tooltips.deviceForm.alias") }}</span>
                </v-tooltip>
              </div>
            </div>
            <div class="col-lg-4 col-12">
              <div class="row d-flex align-center m-0 mb-5">
                <div class="col col-10">
                  <TGCTextField
                    :label="$t('device.deviceForm.radiusOfCoverage')"
                    v-model="form.radiusOfCoverage"
                    :rules="[
                      rules.required,
                      (value) => rules.min(value, 0),
                      (value) => rules.max(value, 100),
                    ]"
                    type="number"
                  />
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="20"
                      class="col col-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{ $t("tooltips.deviceForm.radiusOfCoverage") }}</span>
                </v-tooltip>
              </div>
            </div>
            <!-- <div class="col-lg-4 col-12">
              <div class="row d-flex align-center m-0 mb-5">
                <div class="col col-10">
                  <v-select
                    item-value="id"
                    item-text="name"
                    :items="deviceTypes"
                    label="Device Type"
                    :rules="[rules.required]"
                    v-model="form.deviceTypeId"
                  >
                  </v-select>
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="20"
                      class="col col-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{ $t("tooltips.deviceType") }}</span>
                </v-tooltip>
              </div>
            </div> -->
            <!-- <div class="col-lg-4 col-12">
              <div class="row d-flex align-center m-0 mb-5">
                <div class="col col-10">
                  <v-select
                    item-value="id"
                    item-text="name"
                    :items="technologyTypes"
                    label="Technology types"
                    :rules="[rules.required]"
                    v-model="form.technologyTypeId"
                  >
                  </v-select>
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="20"
                      class="col col-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{ $t("tooltips.technologyType") }}</span>
                </v-tooltip>
              </div>
            </div> -->
            <div class="col-lg-2 col-12">
              <div class="row d-flex align-center m-0 mb-5">
                <div class="col col-10">
                  <TGCTextField
                    :label="$t('device.deviceForm.uplinkInterval')"
                    v-model="form.uplinkInterval"
                    :rules="[
                      rules.required,
                      (value) => rules.min(value, 0),
                      (value) => rules.max(value, 100),
                    ]"
                    type="number"
                  />
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="20"
                      class="col col-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{ $t("tooltips.deviceForm.upLinkInterval") }}</span>
                </v-tooltip>
              </div>
            </div>
            <div class="col-lg-2 col-12">
              <div class="row d-flex align-center m-0 mb-5">
                <div class="col col-10">
                  <v-switch
                    v-model="form.hasInitialPosition"
                    :label="`${
                      form.hasInitialPosition
                        ? $t('device.deviceForm.initialPosition')
                        : $t('device.deviceForm.notInitialPosition')
                    }`"
                  ></v-switch>
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="20"
                      class="col col-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{ $t("tooltips.deviceForm.initialPosition") }}</span>
                </v-tooltip>
              </div>
            </div>
            <div class="col-lg-4 col-12" v-if="form.hasInitialPosition">
              <div class="row d-flex align-center m-0 mb-5">
                <div class="col col-10">
                  <v-select
                    item-value="id"
                    item-text="name"
                    :items="icons"
                    :label="$t('device.deviceForm.icons')"
                    v-model="form.iconId"
                  >
                    <template slot="item" slot-scope="data">
                      <template>
                        <icons-svg
                          :id="data.item.id"
                          :color="form.iconColor"
                          :height="40"
                          :width="40"
                        ></icons-svg>
                        <span> {{ data.item.name }}</span>
                      </template>
                    </template>
                    <template slot="selection" slot-scope="data">
                      <template>
                        <icons-svg
                          :id="data.item.id"
                          :color="form.iconColor"
                          :height="40"
                          :width="40"
                        ></icons-svg>
                        <span> {{ data.item.name }}</span>
                      </template>
                    </template>
                  </v-select>
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="20"
                      class="col col-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{ $t("tooltips.deviceForm.icon") }}</span>
                </v-tooltip>
              </div>
            </div>
            <div class="col-lg-4 col-12" v-if="form.hasInitialPosition">
              <div class="row d-flex align-center m-0">
                <div class="col col-5">
                  <v-text-field
                    :label="$t('device.deviceForm.latitud')"
                    v-model="form.lat"
                    :rules="[rules.required]"
                  />
                </div>
                <div class="col col-5">
                  <v-text-field
                    :label="$t('device.deviceForm.longitude')"
                    v-model="form.lng"
                    :rules="[rules.required]"
                  />
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="20"
                      class="col col-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{ $t("tooltips.deviceForm.latLng") }}</span>
                </v-tooltip>
              </div>
              <div
                class="row d-flex justify-content-center align-center m-0 mb-5"
              >
                <v-btn color="primary" small @click="userLocation">
                  Utilizar ubicación actual</v-btn
                >
              </div>
            </div>
            <div class="col-lg-4 col-12" v-if="form.hasInitialPosition">
              <div class="row d-flex align-center m-0 mb-5">
                <div class="col col-10">
                  <v-color-picker
                    dot-size="10"
                    mode="hexa"
                    swatches-max-height="100"
                    v-model="form.iconColor"
                  ></v-color-picker>
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="20"
                      class="col col-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{ $t("tooltips.deviceForm.color") }}</span>
                </v-tooltip>
              </div>
            </div>
            <div class="col-lg-8 col-12 mb-5" v-if="form.hasInitialPosition">
              <geo-map
                :iconId="form.iconId"
                :iconColor="form.iconColor"
                :parentLatLng="latLng"
                @update="updateLatLng"
              ></geo-map>
            </div>
          </div>
        </v-form>
      </div>
      <div class="md-layout">
        <div class="md-layout-item md-size-100 text-right">
          <tgl-save-button
            ref="saveBtn"
            :entity="entity"
            :dispatch="entity.dispatch"
            action="creado"
            :redirect="entity.redirect"
            :validateForm="handleSubmit"
          ></tgl-save-button>
        </div>
      </div>
    </md-card-content>
  </md-card>
</template>
<script>
import GeoMap from "@/components/Maps/GeoMap";
import IconsSvg from "@/components/Icons/Icons";
import { mapGetters } from "vuex";
import TGCTextField from "@/components/UI/TGCTextField";
import TglSaveButton from "@/components/UI/TglSaveButton.vue";

import form from "@/mixins/form";
export default {
  name: "device-form",
  mixins: [form],
  props: {
    dataBackgroundColor: {
      type: String,
      default: "",
    },
  },
  components: {
    GeoMap,
    IconsSvg,
    TGCTextField,
    TglSaveButton,
  },
  data() {
    return {
      form: {
        identifier: "",
        mac: "",
        alias: "",
        radiusOfCoverage: "0",
        deviceTypeId: 4,
        technologyTypeId: 1,
        uplinkInterval: 0,
        iconId: 1,
        iconColor: "#00D63E",
        lat: -33.4513,
        lng: -70.6653,
        hasInitialPosition: false,
      },
      latLng: [-33.4513, -70.6653],
      map: {
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        attribution:
          '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        zoom: 10,
        center: [-33.4513, -70.6653],
      },
      deviceTypes: [],
      technologyTypes: [],
      icons: [],
      editMode: false,
    };
  },
  watch: {
    "form.lat"() {
      this.latLng = [this.form.lat, this.form.lng];
    },
    "form.lng"() {
      this.latLng = [this.form.lat, this.form.lng];
    },
    "form.hasInitialPosition"() {
      if (!this.$route.params.device) {
        this.form.lat = -33.4513;
        this.form.lng = -70.6653;
      }
    },
  },
  computed: {
    ...mapGetters(["getTheme"]),
    entity() {
      let params = this.form.hasInitialPosition
        ? {
            identifier: this.form.identifier,
            mac: this.form.identifier,
            alias: this.form.alias,
            radius_of_coverage: this.form.radiusOfCoverage,
            technology_type_id: this.form.technologyTypeId,
            device_type_id: this.form.deviceTypeId,
            uplink_interval: this.form.uplinkInterval,
            icon_id: this.form.iconId,
            icon_color: this.form.iconColor,
            lat: this.form.lat,
            lng: this.form.lng,
          }
        : {
            identifier: this.form.identifier,
            mac: this.form.identifier,
            alias: this.form.alias,
            radius_of_coverage: this.form.radiusOfCoverage,
            technology_type_id: this.form.technologyTypeId,
            device_type_id: this.form.deviceTypeId,
            uplink_interval: this.form.uplinkInterval,
          };
      return {
        name: "dispositivo",
        params: this.editMode
          ? {
              ...params,
              id: this.$route.params.device,
            }
          : params,
        dispatch: this.editMode ? "device/editDevice" : "device/createDevice",
        redirect: { name: "devices" },
      };
    },
  },
  mounted: function () {
    this.getTechnologyTypes();
    this.getDeviceTypes();
    this.getIcons();
    if (this.$route.params.device) {
      this.editMode = true;
      this.getDevice();
    }
  },
  methods: {
    getDevice() {
      this.$store
        .dispatch("device/getDevice", this.$route.params.device)
        .then((data) => {
          let device = data;
          this.form.identifier = device.identifier;
          this.form.mac = device.mac;
          this.form.alias = device.alias;
          this.form.radiusOfCoverage = device.radius_of_coverage;
          this.form.hasInitialPosition = device.position ? true : false;
          this.form.lat = device.position
            ? device.position.latitude
            : this.latLng[0];
          this.form.lng = device.position
            ? device.position.longitude
            : this.latLng[1];
          this.form.deviceTypeId = device.device_type_id;
          this.form.technologyTypeId = device.technology_type_id;
          this.form.iconId = device.icon_id ? device.icon_id : null;
          this.form.iconColor = device.icon_color;
          this.form.uplinkInterval = device.uplink_interval;
          this.$refs.form.resetValidation();
          this.latLng = [this.form.lat, this.form.lng];
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTechnologyTypes() {
      this.$store.dispatch("technologyType/getTechnologyTypes").then((data) => {
        this.technologyTypes = data;
      });
    },
    getDeviceTypes() {
      this.$store.dispatch("deviceType/getDeviceTypes").then((data) => {
        this.deviceTypes = data;
      });
    },
    getIcons() {
      this.$store.dispatch("icon/getIcons").then((data) => {
        this.icons = data.reverse();
      });
    },
    handleSubmit() {
      return this.$refs.form.validate();
    },
    geo_success(position) {
      this.form.lat = position.coords.latitude;
      this.form.lng = position.coords.longitude;
    },
    geo_error() {
      alert("Sorry, no position available.");
    },
    userLocation() {
      navigator.geolocation.getCurrentPosition(
        this.geo_success,
        this.geo_error
      );
    },
    updateLatLng(e) {
      (this.form.lat = e[0]), (this.form.lng = e[1]);
    },
  },
};
</script>