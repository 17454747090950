<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <tgl-breadcrumbs :items="breadcrumbs" />
    </div>
    <div class="md-layout-item md-medium-size-100 md-size-100">
      <device-form :data-background-color="getTheme"> </device-form>
    </div>
  </div>
</template>

<script>
import TglBreadcrumbs from "../../components/UI/TglBreadcrumbs";
import DeviceForm from "../../components/Devices/DeviceForm";
import { mapGetters } from "vuex";
export default {
  components: {
    TglBreadcrumbs,
    DeviceForm,
  },
  computed: {
    ...mapGetters(["getTheme"]),
    breadcrumbs() {
      return [
        {
          label: "Dispositivos",
          disabled: false,
          to: { name: "devices" },
        },
        {
          label: "Crear dispositivo",
          disabled: true,
          to: { name: "devices" },
        },
      ];
    },
  },
};
</script>